import React from 'react';

function ContentUnavailable() {
  return (
    <div>
      <div style={{ padding: '300px' }}>
        This content is not available in your currently selected region.
      </div>
    </div>
  );
}

export default ContentUnavailable;
